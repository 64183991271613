<template>
  <v-select
    v-model="$i18n.locale"
    :items="$languages"
    v-bind="$attrs"
  >
    <template
      v-if="$vuetify.breakpoint.mobile"
      #selection="{ item }"
    >
      {{ item.menu | uppercase }}
    </template>
  </v-select>
</template>
<script>
import numeral from 'numeral';

export default {
  computed: {
    numeralLocale () {
      return Object.keys(numeral.locales).includes(this.$i18n.locale) ? this.$i18n.locale : 'en'
    },
  },
  watch: {
    '$i18n.locale' (locale) {
      if (locale === 'iw') {
        locale = 'he'
      }
      this.$vuetify.lang.current = locale
      this.$moment.locale(locale)
      this.$vuetify.rtl = this.$languages.find(l => l.value == this.$i18n.locale)?.rtl || false
      numeral.locale(this.numeralLocale)

      if (this.$auth.check() && this.$auth.user.locale !== locale) {
        this.$auth.user().locale = locale
        this.axios.patch('/admin/users/' + this.$auth.user().uuid, { locale })
      }
    },
  },
}
</script>
